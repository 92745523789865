<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <!-- 登録エリア -->
          <ProductInsertArea />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ProductInsertArea from "../components/ProductInsertArea.vue";

export default {
  name: "ProductInsert",

  components: {
    ProductInsertArea,
  },
  data: () => ({
  }),
  computed: {},
  methods: {
  },
};
</script>